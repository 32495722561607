import * as analytics from 'analytics';
import storeFrontCreatePurchaseOrder from 'apiRequests/storeFrontCreatePurchaseOrder';

export const createTasks = ({
  views,
  storeFrontCreatePurchaseOrderTasks,
  reloadProducts,
  removeLineItemsFlaggedForDeletion,
} = {}) => {
  const placeOrder = async () => {
    const {
      accountSetup: { countryState },
      order,
      cartTotal,
      cartProducts,
      storeFront,
    } = views();

    const { orderNum: orderNumber } = await storeFrontCreatePurchaseOrderTasks.runRequest(
      storeFrontCreatePurchaseOrder(countryState, order)
    );

    // Track order created
    analytics.orderCreated(
      {
        id: orderNumber.toString(),
        products: cartProducts,
        total: cartTotal,
        state: countryState,
      },
      storeFront
    );

    return { orderNumber };
  };

  const viewCart = async () => {
    removeLineItemsFlaggedForDeletion();
    await reloadProducts();
  };

  return { placeOrder, viewCart };
};
